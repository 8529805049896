/* stylelint-disable font-family-no-missing-generic-family-keyword */
.audio-card-container {
  margin: 0px;
  margin-left: 16px;
}

@media (max-width: 900px) {
  .audio-card-container {
    width: unset;
    margin: 16px 0px;
  }
}

.audio-card-container .audio-header {
  margin-top: 8px;
  margin-bottom: 16px;
  margin-left: 8px;
  font-size: 16px;
}

.audio-card-container .toggle-wrapper {
  display: flex;
  align-items: center;
}

.audio-card-container .toggle-wrapper .toggle-text {
  display: inline;
  font-family: 'ProximaNova-Semibold';
  font-size: 14px;
  margin: 0px;
  padding-bottom: 5px;
}

.audio-card-container .footer {
  margin-top: 28px;
  margin-bottom: 8px;
  margin-left: 4px;
}

.audio-card-container .footer .save {
  margin-right: 8px;
}
