/* stylelint-disable font-family-no-missing-generic-family-keyword */
.sharing-options {
  margin-top: 24px;
  margin-bottom: 16px;
}

.sharing-options__header {
  margin: 0px 24px;
}

.sharing-options__link-header {
  font-size: 12px;
  line-height: 16px;
  margin-left: 24px;
  margin-right: 24px;
}

.sharing-options__link {
  margin-top: 4px;
  margin-left: 24px;
  margin-right: 24px;
}

.sharing-options__link .tide-input.medium {
  color: var(--color-p3-6);
  background-color: transparent;
  padding: 5px 12px;
}

.sharing-options__include-bot-meetings,
.sharing-options__include-chat {
  margin-top: 10px;
}

.sharing-options__include-chat {
  display: flex;
}

.sharing-options__include-chat .tide-checkbox--label {
  margin-right: 8px;
}

.sharing-options .copy-link-and-gif-button-wrapper {
  margin: 10px 24px 8px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sharing-options .copy-link-and-gif-button-wrapper > *:not(:first-child) {
  margin-left: 8px;
}

.sharing-options .line-separator {
  background: var(--color-p1-4);
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  height: 1px;
}

.sharing-options .tide-toggle {
  vertical-align: middle;
  height: 32px;
}

.sharing-options .team-visibility-wrapper .team-checkbox {
  display: inline-flex;
}

.sharing-options .team-visibility-wrapper .team-checkbox .tide-paragraph-1 {
  color: var(--color-p1-10);
}

.sharing-options .team-visibility-wrapper .team-checkbox-tooltip {
  display: inline-flex;
  vertical-align: text-bottom;
}

.sharing-options .team-visibility-wrapper .toggle-wrapper .toggle-text {
  display: inline;
  font-family: 'ProximaNova-Semibold';
  color: var(--color-p1-8);
}

.sharing-options .team-visibility-wrapper .toggle-wrapper.disable-chat {
  height: 40px;
}

.sharing-options .team-visibility-wrapper .toggle-wrapper .toggle-tooltip {
  display: inline;
}

.sharing-options .team-visibility-wrapper .toggle-wrapper .toggle-tooltip.allow-chat-label {
  display: inline;
  padding-left: 4px;
}

.sharing-options
  .team-visibility-wrapper
  .toggle-wrapper
  .toggle-tooltip.allow-chat-label
  .public-video-text {
  display: inline;
  font-family: 'ProximaNova-Semibold';
  color: var(--color-p1-8);
}

.sharing-options .team-visibility-wrapper .tide-heading-5.team-visibility {
  margin-bottom: 12px;
}

.sharing-options .meetings-calendar-message-wrapper {
  padding-left: 4px;
  padding-top: 4px;
  font-family: 'ProximaNova-Regular';
}

.sharing-options .meetings-calendar-message-wrapper .message-text {
  display: inline;
  padding-right: 4px;
}

.sharing-options .meetings-calendar-message-wrapper .message-link {
  color: var(--color-p1-8);
}

.sharing-options .meetings-calendar-message-wrapper-error {
  padding-left: 4px;
  padding-top: 4px;
  font-family: 'ProximaNova-Regular';
}

.sharing-options .meetings-calendar-message-wrapper-error .error-icon {
  vertical-align: sub;
}

.sharing-options .meetings-calendar-message-wrapper-error .message-text-error {
  display: inline;
  padding-right: 4px;
  padding-left: 4px;
  color: var(--color-s2-6);
}

.sharing-options .meetings-calendar-message-wrapper-error .message-link-error {
  color: var(--color-p1-8);
}
