.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  user-select: none;
}

.pagination-controls__text {
  color: var(--color-p1-6);
  font-size: 14px;
  font-variant-numeric: tabular-nums;
  margin-left: 8px;
  margin-right: 8px;
  min-width: 72px;
  position: relative;
  text-align: center;
  top: 1px;
}
