.sharing-onboarding-message {
  position: absolute;
  z-index: 1;
  top: -56px;
  right: 32px;
}

.sharing-onboarding-message__text-container {
  display: inline-block;
  line-height: 22px;
  min-width: 164px;
}

.sharing-onboarding-message__text {
  font-size: 14px;
  font-family: var(--main-font-family--semibold);
  background: black;
  color: white;
  padding-top: 2px;
  padding-bottom: 2px;
}

.sharing-onboarding-message__line {
  padding: 4px;
}

.sharing-onboarding-message__arrow {
  position: relative;
  top: 54px;
  right: 5px;
  transform: scale(0.75) matrix(-1, 0, 0, -1, 0, 0);
}
