.video-edit-controls-card {
  margin: 16px 0px 0px 16px;
  padding-bottom: 16px;
}

@media (max-width: 900px) {
  .video-edit-controls-card {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
  }
}

.video-edit-controls-card .edit-section {
  display: flex;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 20px;
  padding-bottom: 20px;
  cursor: pointer;
}

.video-edit-controls-card .edit-section:hover {
  background-color: var(--color-p3-2);
}

.video-edit-controls-card .edit-section .trim-video-header-wrapper {
  display: flex;
  align-items: center;
}

.video-edit-controls-card .edit-section .trim-video-header-wrapper .scissors-icon {
  width: 16px;
  height: 16px;
}

.video-edit-controls-card .edit-section .trim-video-header-wrapper .trim-video-header {
  margin-top: 0px;
  padding-left: 8px;
}

.video-edit-controls-card .edit-header {
  margin-top: 0px;
  padding-bottom: 8px;
  padding-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
}

.video-edit-controls-card .edit-section .header {
  margin-top: 0px;
  padding-left: 8px;
}

.video-edit-controls-card .line-separator {
  background: var(--color-p1-4);
  width: 94%;
  height: 1px;
  align-self: center;
}

.video-edit-controls-card .edit-section.bot-playbook-controls {
  display: block;
}

.video-edit-controls-card .bot-playbook-controls .bot-playbook-header-wrapper {
  display: flex;
  align-items: center;
  padding-left: 0px;
}

.video-edit-controls-card .bot-playbook-controls .bot-playbook-header-wrapper .bot-playbook-header {
  margin-top: 0px;
  padding-left: 8px;
}

.video-edit-controls-card .bot-playbook-controls .description {
  margin: 0;
  margin-top: 4px;
  padding-left: 24px;
}
