.people-with-emails__avatar-list {
  list-style-type: none;
  padding: 0;
  margin: 8px 0px 0px 20px;
  max-height: 232px;
  overflow-y: auto;
}

.people-with-emails__avatar-list-item {
  margin-top: 8px;
}

.people-with-emails__avatar-list-item:first-child {
  margin-top: 0;
}

.people-with-emails__avatar-list-item .user-avatar {
  display: inline-block;
  margin-right: 8px;
}
