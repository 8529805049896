/* stylelint-disable font-family-no-missing-generic-family-keyword */
.viewer-stats__table .tide-table {
  border-collapse: separate;
}

.viewer-stats__table .tide-table thead th span.tide-text-span {
  text-transform: none;
  font-size: 12px;
}

.viewer-stats__table td {
  color: var(--color-p1-10);
  font-size: 14px;
  line-height: 19px;
  max-width: 215px;
  overflow: hidden;
  padding: 6px 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.viewer-stats .tide-table td .tide-div {
  display: inline;
}

.viewer-stats__table .viewer-name {
  font-family: 'ProximaNova-Semibold';
}
