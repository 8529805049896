.trim-buttons {
  margin-top: 24px;
  margin-bottom: 8px;
  display: flex;
  justify-content: flex-start;
}

.trim-buttons .cancel-button {
  margin-left: 8px;
}
