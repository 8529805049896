.video-sharing {
  display: flex;
  margin: 0px 0px 16px 16px;
  flex-direction: column;
  background-color: var(--color-p1-2);
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  border-radius: 3px;
  position: relative;
}

@media (max-width: 900px) {
  .video-sharing {
    margin: 0px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

.video-sharing__loading {
  display: flex;
  width: 100%;
  min-height: 245px;
  justify-content: center;
  align-items: center;
}
