.share-page-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.share-page-error__inner-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 20vh;
}

.share-page-error__heading {
  margin-top: 32px;
}

.share-page-error__subheading {
  color: var(--color-p1-5-5);
  margin-top: 8px;
}

.share-page-error__subheading [role='img'] {
  position: relative;
  top: 2px;
}

.share-page-error__button-container {
  margin-top: 36px;
}

.share-page-error__button-container button {
  margin-left: 8px;
  margin-right: 8px;
}
